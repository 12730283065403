<template>
  <div class="page flex-col">
    <div class="container-body flex-col">
      <!-- 中间部分标语 -->
      <div class="container">
      <div class="row block_3">
        <div class="col-sm-4">
          <p>
            <img class="image_2" referrerpolicy="no-referrer" src="../../assets/img/left-decorative-horizontal-bar.png"/>
          </p>
        </div>
        <div class="col-sm-4">
          <p class="text_6">网络通信方案轻松设计</p>
        </div>
        <div class="col-sm-4">
          <p>
            <img class="image_2" referrerpolicy="no-referrer" src="../../assets/img/right-decorative-horizontal-bar.png"/>
          </p>
        </div>
      </div>
      </div>
      <!-- 热门信息 -->
      <div class="block_1 flex-col">
        <div class="container">
        <div class="block_4 flex-col">
          <div class="text-wrapper_2 flex-col"><span class="text_7">动态资讯</span></div>
          <div class="row info-row">
            <div class="col-lg-6">
              <div class="card img-fluid" @click="getInfo()" >
                <img class="card-img-top" src="../../assets/img/zixun-recommend-1.png" alt="Card image" style="width:100%">
                <div class="card-img-overlay">
                  <h4 class="card-title text_16">中国5G发展现状与未来趋势</h4>
                  <p class="card-text text_17">我国移动通信技术起步虽晚，但在5G标准研发上正逐渐成为全球的领跑者</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="card img-fluid" @click="getInfo()" >
                <img class="card-img-top" src="../../assets/img/zixun-recommend-2.png" alt="Card image" style="width:100%">
                <div class="card-img-overlay">
                  <h4 class="card-title text_16">AI辅助设计时代的三大设计应用思维</h4>
                  <p class="card-text text_17">大家不太适应直接看到方案被自动生成的感受，同时就生成方案能否直接使用的问题引发了不同层...的探讨</p>
                </div>
              </div>
            </div> 
          </div>
        </div>
        </div>
      </div>
      <!-- 咨询列表 -->
      <!-- <div class="block_4 flex-col"> -->
      
      <div class="container">
      <!-- 网格展示 -->
      <div v-for="(item, index) in infoData0" :key="index" class="box_6"  @click="getInfo()" >
        <div class="row">
          <div class="col-sm-4 col-left">
            <img class="image_41" style="width:100%;" referrerpolicy="no-referrer" :src="item.infoPic" />
          </div>
          <div class="col-sm-8 col-rigth">
            <div class="flex-col col-rigth">
            <h3 class="text_71" v-html="item.infoBlurb"></h3>
            <p class="text_811" v-html="item.infoContent"></p>
            <p class="text_151 justify-between" v-html="item.infoTime"></p>
            </div>
          </div>
        </div>
      </div>
      <!--分页组件-->
      <!-- <div class="page-ui">
        <el-pagination
          :current-page="pageInfo.currentPage"
          :page-size="pageInfo.pageSize"
          layout="prev, pager, next"
          :total="pageInfo.pageTotal"
        ></el-pagination>
      </div> -->
    </div>
      <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.currentPage"
        :page-sizes="[2, 5, 10, 20]"
        :page-size="pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.pageTotal"
      ></el-pagination> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "InformationList",
  data() {
    return {
      menuIndex: 3,
      constants: {},
      pageInfo: {
        currentPage: 1,
        pageSize: 10,
        pageTotal: 50
      },
      infoData0: [
        {
          infoPic: require('../../assets/img/zixun1.png'),
          infoBlurb: '基于AI技术的AAPC对室内覆盖增益的研究和评估.',
          infoContent : '在5G移动网络时代，运营商运用发展迅速的AI技术进行天线权值的自优化，可以节省大量人工成本，并且可以随着网络用户分布动态调整。基于AI技术的AAPC应运而生，为5G Massive MIMO 天线调整提供有效的解决方案。',
          infoTime: '2021/05/10',
        },
        {
          infoPic: require('../../assets/img/zixun2.png'),
          infoBlurb: '全球5G SA网络部署有望在2023年迎来拐点',
          infoContent : '最近的报告表明，5G SA的发布频率开始增加，这带来了一种可能性，那就是2023年将成为这种更高版本5G架构开始站稳脚跟的转折点。',
          infoTime: '2022/12/15',
        },
        {
          infoPic: require('../../assets/img/zixun3.png'),
          infoBlurb: '构建“三网融合”通信系统',
          infoContent : '为深刻汲取河南郑州“7·20”特大暴雨灾害造成大面积网络通信中断的教训，切实提升全市应急通信保障能力，淄博市应急管理局超前谋划，精心部署，在全国率先建成地市级三网融合应急通信网络，实现370MHz窄带通信网络、卫星通信网络和应急指挥信息网络深度融合，打通各级应急指挥中心与救援现场“最后一公里”，为指挥调度和应急救援提供技术保障。',
          infoTime: '2022/07/12',
        },
        {
          infoPic: require('../../assets/img/zixun4.png'),
          infoBlurb: 'AI智能切片管理_室内覆盖数字化演进',
          infoContent : '切片技术是5G网络的重要特性，该技术可实现将一个物理网络切割成多个虚拟的端到端的网络，每个虚拟网络之间(包括接入、传输和核心网)逻辑独立，任何一个虚拟网络发生故障都不会影响到其他的虚拟网络。',
          infoTime: '2022/12/15',
        },
        {
          infoPic: require('../../assets/img/zixun5.png'),
          infoBlurb: '2022年千兆城市建设情况，110个城市达标',
          infoContent : '工业和信息化部通报2022年千兆城市建设情况。截至2022年10月底，全国共有110个城市达到千兆城市建设标准，完成总结评估工作，约占所有地级市的三分之一。其中，2021年度建成29个千兆城市，2022年度建成81个千兆城市。从区域分布看，东部地区建成41个千兆城市，中部地区建成29个千兆城市，西部地区建成40个千兆城市。',
          infoTime: '2022/12/20',
        },
        {
          infoPic: require('../../assets/img/zixun6.png'),
          infoBlurb: '天鹅奖重磅揭晓：2022 5G终端全球创新峰会激燃创新盛火',
          infoContent : '12月22日，2022手机创新周暨第十届手机设计大赛收官活动——2022 5G终端全球创新峰会备受关注的中国手机最高奖——天鹅奖亦隆重揭晓。本届峰会以“创新智能时代，共赢数字未来”为主题，以“树标杆、提品质、育生态、促发展”为初心，促进5G应用普及和终端产业创新，助力产业链供应链协同与稳定，推动终端产业的创新发展与生态繁荣。',
          infoTime: '2022/12/22',
        },
        {
          infoPic: require('../../assets/img/zixun8.png'),
          infoBlurb: '谁是数字技术“高手”：2022通信设备商100强出炉',
          infoContent : '通信业因技术而生，因技术进步而发展。在驶入数字经济的大航道的背景下，通信业在构建全球最大规模先进信息通信设施的同时，培育、促进了规模庞大、布局完善的通信产业生态，催生了一批领先或同步全球的通信设备主导企业和细分领域隐形冠军。',
          infoTime: '2022/12/16',
        },
      ],
    };
  },
  created() {
    localStorage.setItem("menuIndex", this.menuIndex);
  },
  methods: {

    getInfo() {
      window.location.href = this.baseUrl+"InformationDetails";
		},

    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
    },
    handleCurrentChange(val) {
      this.pageInfo.currentPage = val;
    }


  }
};
</script>
<style scoped lang="css" src="../../assets/informationList.css" />